import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import { setScrollHidden } from "@/libs/utils";
import VehicleCard from "@/components/VehicleCard";
import ShareListingModal from "@/components/listing/ShareListingModal";
import HeartBlackIcon from "@/components/Icon/HeartBlackIcon";
import ShareBlackIcon from "@/components/Icon/ShareBlackIcon";
import { useAuthValue } from "@/contexts/contextAuth";
import { SIMILAR_VIEW_LIMIT } from "@/libs/constants";
import { publicDomain } from "@/libs/clientConfig";
import Head from "next/head";

type Props = {
  similarData: Array<any>;
  slug: string;
};

const ListingNoAvailable = ({ similarData, slug }: Props) => {
  const router = useRouter();

  const { setIsSignup, isShow } = useAuthValue();
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [shareLisingModalIsOpened, setShareListingModalIsOpened] =
    useState<boolean>(false);
  const [similarCnt, setSimilarCnt] = useState<number>(SIMILAR_VIEW_LIMIT);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Head>
        <meta name="robots" content="noindex"></meta>
      </Head>
      <div className="w-full md:max-w-[1000px] mx-auto">
        <div
          className={`${
            scrollPosition > 56 && "fixed top-[56px]"
          } w-full bg-white flex sm:hidden justify-between items-center py-6 sm:pt-2 z-50`}
        >
          <button
            className="flex items-center hover:underline cursor-pointer px-4 md:px-0 py-4"
            onClick={() => router.push("/buy")}
          >
            <span>
              <Image
                width={14}
                height={14}
                src="/assets/listings/arrow.png"
                alt="back"
              />
            </span>
            <span className="text-base text-[#212529] font-medium leading-5">
              &nbsp;&nbsp;Back to search
            </span>
          </button>
          <div className="flex items-center space-x-4 px-4">
            <HeartBlackIcon
              className="cursor-pointer filter drop-shadow-md"
              onClick={() => {
                setIsSignup(true);
                setScrollHidden();
              }}
            />
            <ShareBlackIcon
              className="cursor-pointer filter drop-shadow-md"
              onClick={() => {
                setShareListingModalIsOpened(true);
                setScrollHidden();
              }}
            />
          </div>
        </div>
        <div
          className={`${
            scrollPosition > 122
              ? "fixed top-[0px] w-full md:max-w-[1000px] py-6"
              : "pt-8"
          } w-full bg-white hidden sm:flex justify-between items-center z-40`}
        >
          <button
            className="flex items-center hover:underline cursor-pointer px-4 md:px-0 py-4"
            onClick={() => router.push("/buy")}
          >
            <span>
              <Image
                width={14}
                height={14}
                src="/assets/listings/arrow.png"
                alt="back"
              />
            </span>
            <span className="text-base text-[#212529] font-medium leading-5">
              &nbsp;&nbsp;Back to search
            </span>
          </button>
          <div className="flex items-center space-x-4 px-4">
            <HeartBlackIcon
              className="cursor-pointer filter drop-shadow-md"
              onClick={() => {
                setIsSignup(true);
                setScrollHidden();
              }}
            />
            <ShareBlackIcon
              className="cursor-pointer filter drop-shadow-md"
              onClick={() => {
                setShareListingModalIsOpened(true);
                setScrollHidden();
              }}
            />
          </div>
        </div>
        <div className="w-full flex justify-center pt-6">
          <Image
            width={212}
            height={200}
            src="/assets/no-search-results.svg"
            className="w-[50%] sm:w-auto "
            alt="pageNotFound"
          />
        </div>
        <div className="text-center">
          <p className="text-2xl font-bold opacity-95 pt-4">
            Listing no longer available
          </p>
          <p className="text-base font-medium opacity-95">
            The listing you were looking for isn&apos;t found.
          </p>
        </div>
        <div className="w-full flex justify-center py-6">
          <button
            className="bg-white hover:opacity-80 text-sm text-[#00b3de] font-medium py-2 px-4 border border-[#00b3de] rounded"
            onClick={() => router.push("/buy")}
          >
            Browse all vehicles
          </button>
        </div>
        {Array.isArray(similarData) && similarData.length > 0 && (
          <section className="w-full pt-8">
            <p className="text-xl sm:text-[28px] font-semibold pb-4">Similar</p>
            <div className="w-full px-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 md:gap-10 py-4">
              {Array.isArray(similarData)
                ? similarData.map((item: any, index: number) => {
                    if (index < similarCnt) {
                      return (
                        <div key={index} className="col-span-1 w-full h-full">
                          <VehicleCard data={item} index={index} />
                        </div>
                      );
                    }
                  })
                : ""}
            </div>
          </section>
        )}
        {Array.isArray(similarData) && similarData.length > similarCnt && (
          <div className="w-full flex justify-center py-3">
            <button
              className="bg-[#00b3de] hover:opacity-80 text-white font-bold py-1 sm:py-2 px-2 xs:px-3 rounded cursor-pointer"
              onClick={() => {
                setSimilarCnt(similarData.length);
              }}
            >
              See more similar cars
            </button>
          </div>
        )}
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-5/6 text-xs text-[#212529] text-center pt-8 pb-12">
            The vehicle information is supplied by the seller and may include
            data from third parties; PrivateAuto is not responsible for the
            accuracy of such information. PrivateAuto provides this service and
            materials without representations or warranties of any kind, either
            expressed or implied.
          </div>
        </div>
        {shareLisingModalIsOpened && (
          <ShareListingModal
            shareLisingModalIsOpened={shareLisingModalIsOpened}
            setShareListingModalIsOpened={setShareListingModalIsOpened}
            url={`${publicDomain()}/listing/${slug}`}
          />
        )}
      </div>
    </>
  );
};

export default ListingNoAvailable;
