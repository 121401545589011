import { useEffect, useRef } from "react";
import { setScrollHidden } from "@/libs/utils";
import Image from "next/image";
import useOutsideClick from "@/hooks/useOutsideClick";

type Props = {
  shareLisingModalIsOpened: boolean;
  setShareListingModalIsOpened: Function;
  url: string;
};

const ShareListingModal = ({
  shareLisingModalIsOpened,
  setShareListingModalIsOpened,
  url,
}: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(url);
    } catch (e: any) {
      console.error("Failed to copy value to clipboard:", e);
    }
  };

  useEffect(() => {
    if (shareLisingModalIsOpened) {
      window?.dataLayer &&
        window.dataLayer.push({
          event: "Share Listing",
          Login: "pre",
        });
    }
  }, [shareLisingModalIsOpened]);

  useOutsideClick(
    modalRef,
    shareLisingModalIsOpened,
    setShareListingModalIsOpened
  );

  return (
    <div className="fixed top-0 left-0 w-full h-screen flex justify-center bg-[#000] bg-opacity-50 overflow-x-hidden overflow-y-hidden inset-0 z-50 outline-none focus:outline-none">
      <div
        className="absolute w-full vs:w-[500px] bg-white shadow-2xl rounded-lg mt-[100px]"
        ref={modalRef}
      >
        <div className="w-full flex justify-between px-8 py-8">
          <Image width={134} height={24} src="/assets/logo.svg" alt="logo" />
          <Image
            width={24}
            height={24}
            className="cursor-pointer"
            src="/assets/close.svg"
            alt="close"
            onClick={() => {
              setShareListingModalIsOpened(false);
              setScrollHidden();
            }}
          />
        </div>
        <div className="w-[85%] vs:w-[70%] mx-auto py-2 mb-16">
          <p className="w-full text-[27px] vs:text-[32px] font-bold text-[#212529] px-3 mb-4">
            Share your listing
          </p>
          <input
            type="text"
            className="form-control block w-full px-2 py-1.5 text-base text-[#495057] font-medium bg-white bg-clip-padding border border-solid border-grey rounded cursor-text focus:border-[#a3b0ca] focus:text-gray-700 focus:bg-white focus:outline focus:outline-[#cbced4] focus:outline-4 transition-all"
            value={url}
            disabled
          />
          <p className="font-base text-[#212529]">
            Share with friends, interested parties, potential buyers, and market
            on social media.
          </p>
          <button
            className="w-full bg-[#00b3de] hover:bg-white text-white hover:text-[#00b3de] text-sm font-bold py-3 px-4 border border-[#00b3de] rounded cursor-pointer mt-3"
            onClick={copyToClipboard}
          >
            Copy Link to clipboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareListingModal;
