const ShareBlackIcon = (props: any) => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.68527 5.5C1.28527 5.5 0.518603 11.8333 1.18527 15C3.58524 10.6 7.85195 10.1667 9.6853 10.5V15L17.1853 8L9.68527 1V5.5Z"
        stroke="#212529"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShareBlackIcon;
